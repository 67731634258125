/* Navbar.css */

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #030E22;
    height: 80px;
    width: 80%; /* Adjusted width */
    border-radius: 15px; /* Rounded corners */
    margin: auto;
  }
  
  .search-bar {
    width: 10%; /* 10% width */
    border-radius: 10px; /* Rounded corners */
  }
  
  .search-bar input {
    padding: 8px;
    width: 100%;
    border: none;
    border-radius: 8px; /* Rounded corners */
  }
  
  .nav-links {
    display: flex;
    justify-content: center; /* Center the links */
    flex: 1; /* Take remaining space */
  }
  
  .nav-links a {
    margin: 0 10px;
    text-decoration: none;
    color: white;
  }
  
  .login-button {
    width: 8%;
    padding: 8px;
    background-color: transparent;
    border: 1px solid white; 
    border-radius: 20px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; 
  }
  
  .login-button:hover {
    background-color: #fff;
    color: #030E22;
    border: none; 
  }


.hamburger-menu {
  display: none; /* Hide the hamburger menu by default */
  cursor: pointer;
  margin-right: 10px; /* Add some margin to the right of the hamburger menu */
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s; /* Add a smooth transition effect for the hamburger menu */
}

/* Style for toggling the hamburger icon on menu click */
.change .hamburger-menu div:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.change .hamburger-menu div:nth-child(2) {
  opacity: 0;
}

.change .hamburger-menu div:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media (max-width: 900px) {
    .navbar-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
    }
  
    .nav-links a {
      margin: 10px 0;
    }
  
    .login-button {
      display: none;
      width: auto;
    }
  
    .hamburger-menu {
      display: flex;
      
    }
  
    .search-bar {
      width: 100%;
      margin-top: 1rem;
    }
  
    .menu-open .search-bar {
      display: flex;
    }
  
    .nav-links.show,
    .login-button.show {
      display: flex;
      z-index: 1;
      background: #0F4E9E;
    }
  }
  