/* .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  } */
  .text-container {
    flex: 1;
    margin-left: -120px;
  }
  
  .image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: -320px;
  }
  
  /* Adjust these styles as needed */
  .text-container h1 {
    font-size: 54px;
    margin-bottom: 10px;
    color: #FFFFFF;
    font-style: Inter;
  }
  
  .text-container h2 {
    font-size: 18px;
    color: #FFFFFF;
    font-style: Inter;
  }
  
  .image-container img {
    max-width: 110%;
    height: auto;
  }
  .form-container {
    border-radius: 5px 25px 25px 25px;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.container-arrow {
font-family: 'Lato', sans-serif;
/* width: 40%; */
/* margin: 0 auto; */
  margin-top: -187px;
  margin-left: -20px;
  margin-bottom: -80px;
}

.wrapper {
display: table-cell;
height: 400px;
vertical-align: middle;
}
.arrow-steps .step {
	font-size: 14px;
	text-align: center;
	color: #666;
	cursor: default;
	margin: 0 3px;
	padding: 10px 10px 10px 10px;
	min-width: 180px;
	float: left;
	position: relative;
	background-color: #d9e3f7;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; 
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 45px solid transparent;
	border-bottom: 45px solid transparent;
	border-left: 17px solid #d9e3f7;	
	z-index: 2;
    transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;	
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
	position: relative;
}

.arrow-steps .step span:before {
	opacity: 0;
	content: "✔";
	position: absolute;
	top: -2px;
	left: -20px;
}

.arrow-steps .step.done span:before {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease 0.5s;
	-moz-transition: opacity 0.3s ease 0.5s;
	-ms-transition: opacity 0.3s ease 0.5s;
	transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
	color: #fff;
	background-color: #23468c;
}

.arrow-steps .step.current:after {
	border-left: 17px solid #23468c;	
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  margin-bottom: 10px;
}

.left-text, .right-text {
  margin-right: 10px;
  margin-left: 10px;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #84B6F3;
  border-radius: 34px;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #0F4E9E;
  border-radius: 50%;
  transition: .4s;
}

input:checked + .slider {
  background-color: #84B6F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #058CBD !important;
  border-color: #058CBD !important;
}

/* .bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
} */
.heading-container{
  display: flex;
  flex-direction: row;
}
.logo-container {
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}

.company-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.company-banner{
  width: 1200px;
  height: 200px;
  object-fit: cover;
}
/* .company-logo {
  width: 6rem;
  height: 6rem;
  size: 100;
  object-fit: cover;
  border-radius: 50%;
} */
.ant-select-item {
  border-bottom: 1px solid #d9d9d9; /* Light gray border between options */
}

/* Remove border from the last option to avoid extra border at the end */
.ant-select-item:last-child {
  border-bottom: none;
}

.custom-select .ant-select-arrow {
  font-size: 16px !important;
  transition: transform 0.3s;
}

/* Rotate the arrow when dropdown is open */
.custom-select.ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
}

  @media (max-width: 900px) {
    /* .container {
      flex-direction: column;
      align-items: flex-start;
    } */
  
    .text-container {
      margin-left: 0;
      margin-bottom: 20px; /* Add spacing between text and image in mobile view */
    }
  
    .image-container {
      margin-right: -20px;
    }
    
    .container-arrow {
      margin-left: 50px;
      margin-top: -50px
    }
    .text-container h1{
      font-size: 30px;
      margin-top: 10px;
      margin-left: 30px;
    }
    .text-container h2 {
      font-size: 20px;
      margin-left: 30px;
    }
    .heading-container{
      flex-direction: column;
    }
    
  }
  :root {
    --btn-bg-color: rgb(5, 140, 189);  /* Button ka background color */
    --btn-text-color: white;  /* Button ka text color */
    --text-color: rgb(30, 30, 30);  /* General text ka color (headings, paragraphs, etc.) */
  }

  .counter-button button{
    /* background-color: initial !important; */
    background-color: var(--btn-bg-color) !important;
    color: var(--btn-text-color) !important;
    border: initial !important;
    all: unset;
  }

  
  
  /* Button ka style */
  button {
    background-color: var(--btn-bg-color) !important;
    color: var(--btn-text-color) !important;
    border: 2px solid var(--btn-bg-color) !important;
    border-radius: 8px;
    padding: 17px 35px;
    font-size: 17px;
  }
  
  /* Global text color (Headings, paragraphs, etc.) */
  h1, h2, h3, h4, h5, h6, p, span, li, a {
    color: var(--text-color) !important;
  }
  
/* Header elements responsiveness */
.row .col-md-4 {
  margin-bottom: 10px; /* Add spacing for mobile stacking */
}

@media (max-width: 768px) {
  /* Logo adjustments */
  .logo-container img {
    max-width: 80px; /* Smaller logo for mobile */
    height: auto;
  }

  /* Button adjustments */
  .btn-primary {
    width: auto; /* Prevent button from stretching */
    font-size: 14px; /* Smaller font size */
    padding: 10px 16px; /* Adjust padding for mobile */
  }

  /* Company name font size */
  .font-weight-600 {
    font-size: 1.5rem; /* Adjust heading size for mobile */
  }

  /* Center-align elements */
  .text-md-start {
    text-align: center;
  }
  .text-md-end {
    text-align: center;
  }
}
/* Ensure the logo container centers its content */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .logo-container img {
    max-width: 100px; /* Smaller logo for mobile */
    height: auto;
  }
}
/* .responsive-scale {
  transform: scale(0.85);
  transform-origin: top center;
} */

@media (max-width: 768px) {
  /* For mobile screens (768px or smaller) */
  .responsive-scale {
    transform: none; /* Resets the scaling for mobile screens */
  }
}
/* Customizing the current step's background and text color */
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--btn-bg-color) !important; /* Use your custom background color */
  color: var(--text-color) !important; /* Use your custom text color */
}

.ant-steps-item-process .ant-steps-item-title {
  color: var(--text-color) !important;
}
/* Set the line color to make it more visible */
.ant-steps-item-tail::after {
  background-color: var(--btn-bg-color); /* Use your desired color variable */
  height: 2px; /* Adjust thickness */
}
/* Reduce the spacing between step items */
/* .ant-steps-item {
  flex: 0 1 80px;
  padding: 0;
} */

/* Adjust the title placement for closer alignment */
/* .ant-steps-item-title {
  margin-top: 4px;
  width:129%;
} */
