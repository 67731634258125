.confirm-square {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.square-confirm-1 {
  width: 100% !important;
    height: 100px;
    background-color: #c8dbf1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.confirm-container {
    display: flex;
    flex-wrap: wrap;
    width:100% !important;
    margin: auto;
}
.column {
    flex: 1;
    margin-right: 10px;
}

.dotted-line-confirm {
    position: relative;
    width: 103%; 
    height: 5px;
    margin-top: -5px;
    margin-bottom: -5px;
    background-image: repeating-linear-gradient(90deg, transparent, transparent 5px, white 5px, white 10px);
  }
.dotted-line-confirm::before,
.dotted-line-confirm::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #F6F6F6;
  border-radius: 50%;
}

.dotted-line-confirm::before {
  left: 0;
}

.dotted-line-confirm::after {
  right: 0;
}
.square-confirm-2 {
  width: 100% !important;
  border: 2px solid, #00000014;
  padding: 20px;
  border-radius: 20px;
  overflow: auto;
}
.square-confirm-3 {
    width: 100% !important;
    border: 2px solid, #00000014;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.square-confirm-5 {
    width: 400px;
    height: 280px;
    background-color: #c8dbf1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
}
/* Right Side Column Squares CSS */
.square-confirm-4 {
    width: 100% !important;
    border: 2px solid, #00000014;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}
.square-confirm-6 {
    width: 100% !important;
    height: 100px;
    background-color: #c8dbf1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.dotted-line-confirm-2 {
    position: relative;
    width: 105% !important; 
    height: 5px;
    margin-top: -5px;
    margin-bottom: -5px;
    background-image: repeating-linear-gradient(90deg, transparent, transparent 5px, white 5px, white 10px);
  }
.dotted-line-confirm-2::before,
.dotted-line-confirm-2::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #F6F6F6;
  border-radius: 50%;
}

.dotted-line-confirm-2::before {
  left: 0;
}

.dotted-line-confirm-2::after {
  right: 0;
}

/* Test CSS */

.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .info-row-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 100%;
  }
  
  .left-square-2 {
    width: calc(48% - 10px);
    margin-right: 150px;
    max-height: 100px;
  }
  .right-square-2{
    width: calc(48% - 10px);
    max-height: 100px;
    margin-right: 20px;
    margin-top: -20px;
    
  }
  .center-square-2 {
    width: 4%;
    margin-right: 100px;
    display: flex;
    align-items: center;
  }
  .gps-image {
    width: 80%;
    margin-left: 30px;
    height: auto;
    border-radius: 10px;
  }
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  .card-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  .bold-label {
    font-weight: bold;
  }
  .label-number-pair {
    display: grid; 
    grid-template-columns: auto auto; 
    align-items: center; 
  }
  .number-label {
    margin-right: 50px;
  }
  .duration {
    color: #0F4E9E;
    font-size: 15px;
  }
  .pricing-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 80%;
  }
  .checkbox-container {
    display: flex;
    align-items: center;
  }
  .checkbox-container label{
    font-size: 14px;
  }
  .info-row-field input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
  }
  .price-row-confirm {
    display: flex;
    justify-content: space-between;
  }
  .price-row-confirm p {
    font-size: 16px;
  }
  .price-total {
    font-weight: bold;
  }
  .promo-code-confirm {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .promo-code-confirm input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 65%;
  }
  .square-confirm-7 {
    width: 100% !important;
    border: 2px solid, #00000014;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.left-content-confirm img {
  width: 100px;
  height: auto;
}

.right-content-confirm {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 10px;
}

.right-content-confirm p {
  margin-right: 20px; 
}

.svg-container-confirm svg {
  width: 20px; 
  margin-left: 10px;
  margin-top: -20px;
}
.button-reserve {
  background-color: #01042D;
  color: #FFFFFF;
  border: 1.78px solid #01042D;
  cursor: pointer;
  padding: 15.11px, 31.31px, 16.89px, 30.23px;
  border-radius: 7.11px;
  font-size: 20px;
  width: 200px;
  height: 60px;
}
.button-reserve:hover {
  background-color: #01042D;
  color: #FFFFFF;
}
.square-confirm-map {
  width: 100% !important;
  border: 2px solid #00000014;
  background-color: #ffffff;
  border-radius: 20px;
  position: relative;
  height: 425px;
  overflow: hidden;
}

.image-map {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.total-divider {
  border: 0;
  border-top: 2px solid #ddd;
}
.small-container{
  width: 100% !important;
  border: 2px solid #0000001A;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 5px;
}
.img-custom {
  width: 600px;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
}
.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 900px) {
  .new-container-stats{
    flex-direction: column;
    gap: 10px;
  }
  .upside{
    flex-direction: column;
  }
  .button-reserve{
    width: 200px;
    height: 50px;
  }
}


/* 
.slick-list{
  width: 300px;
  height: 150px;
} */
.custom-carousel .slick-list {
  width: 300px !important;
  height: 150px !important;
  overflow: hidden; /* Ensure no vertical display issues */
}

.custom-carousel .slick-slide {
  display: flex !important; /* Ensure images are aligned correctly */
  justify-content: center;
  align-items: center;
}
.custom-carousel .slick-prev,
.custom-carousel .slick-next {
  font-size: 20px; /* Adjust the arrow size */
  color: #333; /* Change arrow color */
  z-index: 2; /* Ensure arrows are above the content */
  background: rgba(255, 255, 255, 0.7); /* Add a semi-transparent background */
  border-radius: 50%; /* Make the arrows circular */
  width: 40px; /* Set width */
  height: 40px; /* Set height */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for a sleek look */
}
.custom-carousel .slick-prev {
  left: 0px; /* Adjust position to the left */
}

.custom-carousel .slick-next {
  right: 0px; /* Adjust position to the right */
}
/* Hover effects for arrows */
.custom-carousel .slick-prev:hover,
.custom-carousel .slick-next:hover {
  background: #000; /* Change background color on hover */
  color: #fff; /* Change arrow color on hover */
}

/* Hide default outlines when focused */
.custom-carousel .slick-prev:focus,
.custom-carousel .slick-next:focus {
  outline: none;
}

.custom-carousel .slick-prev::after,
.custom-carousel .slick-next::after {
  display: none; /* Completely hide the default arrows */
}

@media (max-width: 768px) {
  .confirm-square {
    margin: 0 -3rem; /* Add margin on left and right */
    box-sizing: border-box; /* Ensure padding doesn’t overflow */
  }
  .col-lg-5{
    width: 100%;
  }
}
input.form-control {
  text-align: left; /* Align text to the left */
}
.p-4 {
  padding: 3.5rem !important;
}