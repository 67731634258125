@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Petrov Sans';
  src: url('./fonts/PetrovSansTrial-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'Petrov Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999; /* color of the thumb */
  border-radius: 4px; /* roundness of the thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; /* color of the thumb on hover */
}

@media (min-width: 1024px) and (max-width: 1440px) {
  body {
    zoom: 80%;
  }
}
.checkout-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

*{
  font-family: 'Petrov Sans', sans-serif !important;
}

.checkout-content {
  width: 100%;
  /* max-width: 800px; */
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ant-card-head {
  background: linear-gradient(to right, #01A7DC 40%, #0A4676 100%) !important;
  color: white !important;
} 
.data-table {
  margin-top: 20px;
  background-color: #0A4676 !important;
}

.simple-map-container button {
  background-color: initial !important;
  color: initial !important;
  border: initial !important;
  all: unset; /* Ensure no global styles apply */
}

/* .scale-90-laptop {
  transform: scale(0.90);
} */