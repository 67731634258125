


.nav {
	margin-top: 40px;
}

.pull-right {
	float: right;
}

a, a:active {
	color: #333;
	text-decoration: none;
}

a:hover {
	color: #999;
}

/* Breadcrups CSS */



 /* Form CSS*/

 .container-form {
    width: 90%; 
    /* max-width: 600px;  */
    margin: 0 auto; 
    padding: 20px; 
    box-sizing: border-box;
 }
 .form-row {
    display: flex;
    justify-content: space-between;
  }
  
  .form-column {
    flex: 1;
    /* margin-right: 10px; */
  }
  
  .form-column:last-child {
    margin-right: 0;
  }
  
  .form-row label {
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .form-row select {
    padding: 8px;
    border: 1px solid #D0D5DD;
    background-color: #faf9f9;
    border-radius: 4px;
    width: 85%;
  }
  .form-row .wider-select {
    width: 90%;
}
  .form-row .selectfield {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80%;
  }
  .form-row .inputfield {
    padding: 8px;
    border: 1px solid #ccc;
    background-color: #faf9f9;
    border-radius: 4px;
    width: 90%;
  }
  /* .form-row .dateandtime {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
  } */
  .react-datepicker-wrapper {
    width: 87%;
  }
  .dateandtime{
    padding: 8px;
    border: 1px solid #0000001A;
    border-radius: 4px;
  }
  
  .button-submit {
    background-color: #01042D;
    color: #FFFFFF;
    padding: 8px 36px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 30px;
    /* float: right; */
    /* margin-right: 70px; */
    
}

.button-addstop {
    background-color: #0F4E9E;
    color: #FFFFFF;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
}
.form-label {
  margin-right: 5px;
}
.hide-flight-details {
  border-radius: 20px; 
  padding: 8px 16px; 
  background-color: transparent; 
  color: #0F4E9E;
  border: 2px solid #0F4E9E;
  cursor: pointer;
  float: right;
  margin-right: 70px;
  margin-top: -10px;
}

.hide-new-details{
  color: #058CBD;
  font-size: 16px;
  cursor: pointer;
  float: right;
  margin-right: 70px;
  margin-top: -10px;
}
.eye-icon{
  cursor: pointer;
  float: right;
  margin-right: 10px;
  margin-top: -10px;
}
.hide-flight-details:hover {
  background-color: #0F4E9E;
  color: #FFFFFF;
}

/* Styles for the location input field */
#location {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 95%;  
}
.text-area{
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 95%;
  height: 90%;
}

/* .counter {
  display: flex;
  align-items: center;
} */

.count {
  margin: 0 10px;
  font-size: 30px;
}

/* svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
} */

.increment,
.decrement {
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.increment:hover,
.decrement:hover {
  background-color: #f0f0f0;
}
/* .ant-picker-suffix .anticon {
  color: #49494bcc;
} */

.DateTime{
  width: 87%;
  padding: 8px;
  border: 1px solid ;
  border-color: #D0D5DD;
  background-color: #faf9f9;
  border-radius: 4px;
}

.DateTime2{
  width: 100%;
  padding: 8px;
  border: 1px solid ;
  border-color: #D0D5DD;
  background-color: #faf9f9;
  border-radius: 4px;
}

@media (max-width: 900px) {
  .form-row {
    flex-direction: column;
  }
  .hide-flight-details {
    float: none; 
    margin: 0 auto; 
    margin-top: 10px; 
  }
  .hide-new-details{
    display: none;
     
  }
  .eye-icon{
    float: end; 
    margin-right: 40px;
    
  }
  .form-row select{
    width: 100%;
  }
  .form-row .selectfield {
    width: 100%;
  }
  .form-row .inputfield {
    width: 100%;
  }
  
}

.custom-date-picker-dropdown .ant-picker-header {
  background-color: #ffff !important;
  color: #ffff !important;
}

.custom-date-picker-dropdown .ant-picker-header button{
  background-color: #ffff !important;
  border-color: #ffff !important;
  color: #000000 !important;
}

.mb-3 .form-select.car-seat-select {
  height: 40px !important; /* Increase height */
  border: 1px solid rgb(204, 204, 204) !important; /* Set border color */
  color: rgb(204, 204, 204) !important; /* Set text color */
}
.selectfield {
  height: 40px !important; /* Increase height */
  border: 1px solid rgb(204, 204, 204) !important; /* Set border color */
  /* color: rgb(150, 146, 146) !important; Set text color */
  border-radius: 5px;

}
.customSelect .ant-select-selector {
  background-color: #faf9f9 !important;
}

.custom-select-placeholder {
  color: #BFBFBF !important;
}

@media (max-width: 786px) {
  .container-form {
    width: 110%;
    /* max-width: 600px; */
    margin: 0 auto;
    padding: 0px;
    box-sizing: border-box;
}
  
}
/*modal*/
/* Ensures that the container can wrap its content */
.seat-dropdown-container {
  width: 100%; /* Full width of the parent container */
  margin-bottom: 15px; /* Adds spacing between items */
}

/* Flexbox for larger screens */
@media (min-width: 768px) {
  .seat-dropdown-container {
    flex: 0 0 48%; /* Each dropdown takes up 48% of the width */
    margin-right: 4%; /* Adds space between columns */
  }

  .seat-dropdown-container:last-child {
    margin-right: 0; /* Ensures the last dropdown does not have extra margin */
  }

  /* Parent wrapper with flexbox */
  .seat-dropdowns-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adds gap between elements */
  }
}

/* Mobile view where dropdowns stack vertically */
@media (max-width: 767px) {
  .seat-dropdowns-wrapper {
    display: block; /* Stacks dropdowns vertically */
  }

  .seat-dropdown-container {
    width: 100%; /* Takes full width of the screen */
  }
}
/* For mobile screens, the dropdown takes full width */
@media (max-width: 767px) {
  .dropdown-button {
    width: 100%; /* Takes full width on mobile */
  }
}

/* For larger screens, set the width to 470% */
@media (min-width: 768px) {
  .dropdown-button {
    width: 470%; /* This is very wide, make sure it's intended */
  }
}
.seat-dropdowns-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Space between dropdowns */
}

.seat-dropdowns-wrapper .dropdown-item {
  width: 100%; /* Ensures full width for each dropdown */
}
